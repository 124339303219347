import { createContext, useContext } from 'react';
import { UseFormReturnType, useForm } from '@mantine/form';

import { LogInRequest } from '../../@types/auth';

type ILogInFormContext = UseFormReturnType<LogInRequest>;

const LogInFormContext = createContext<ILogInFormContext | undefined>(
  undefined,
);

export function useLogInFormContext() {
  const logInForm = useContext(LogInFormContext);

  if (!logInForm) {
    throw new Error(
      'useLogInFormContext must be used within a LogInFormProvider',
    );
  }

  return logInForm;
}

export const LogInFormProvider = ({
  children,
}: React.ComponentPropsWithoutRef<'div'>) => {
  const form = useForm<LogInRequest>({
    initialValues: {
      username: '',
      password: '',
    },
    validate: values => ({
      username: !values.username ? 'Email is required' : null,
      password: !values.password ? 'Password is required' : null,
    }),
  });

  return (
    <LogInFormContext.Provider value={form}>
      {children}
    </LogInFormContext.Provider>
  );
};
