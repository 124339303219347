import { createContext, useContext } from 'react';
import { UseFormReturnType, useForm } from '@mantine/form';

interface TwoFactorFormValues {
  otp: string;
}

type ITwoFactorFormContext = UseFormReturnType<TwoFactorFormValues>;

const TwoFactorFormContext = createContext<ITwoFactorFormContext | undefined>(
  undefined,
);

export function useTwoFactorFormContext() {
  const twoFactorForm = useContext(TwoFactorFormContext);

  if (!twoFactorForm) {
    throw new Error(
      'useTwoFactorFormContext must be used within a TwoFactorFormProvider',
    );
  }

  return twoFactorForm;
}

export const TwoFactorFormProvider = ({
  children,
}: React.ComponentPropsWithoutRef<'div'>) => {
  const form = useForm<TwoFactorFormValues>({
    initialValues: {
      otp: '',
    },
    validate: {
      otp: value => {
        if (!value) {
          return 'One-time code is required.';
        }

        if (value.length < 6) {
          return 'One-time code must be 6 digits.';
        }

        return null;
      },
    },
  });

  return (
    <TwoFactorFormContext.Provider value={form}>
      {children}
    </TwoFactorFormContext.Provider>
  );
};
