import { PASSWORD_REQUIREMENTS } from '../constants/signUpForm';

import {
  LOWERCASE_REGEX,
  NUMBER_REGEX,
  SYMBOL_REGEX,
  UPPERCASE_REGEX,
} from '../constants/customPasswordInput';

export function emailValidator(email: string) {
  if (!email) {
    return 'Email is required';
  }

  const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

  if (!emailRegEx.test(email)) {
    return 'Email is invalid';
  }

  return null;
}

export function passwordValidator(password: string) {
  const {
    minLength,
    needsLowerCase,
    needsUpperCase,
    needsNumber,
    needsSymbol,
  } = PASSWORD_REQUIREMENTS;

  if (!password) {
    return 'Password is required';
  }

  if (password.length < minLength) {
    return `Password must be at least ${minLength} characters long`;
  }

  if (needsLowerCase && !LOWERCASE_REGEX.test(password)) {
    return 'Password must contain at least one lowercase letter';
  }

  if (needsUpperCase && !UPPERCASE_REGEX.test(password)) {
    return 'Password must contain at least one uppercase letter';
  }

  if (needsNumber && !NUMBER_REGEX.test(password)) {
    return 'Password must contain at least one number';
  }

  if (needsSymbol && !SYMBOL_REGEX.test(password)) {
    return 'Password must contain at least one symbol';
  }

  return null;
}
