export default async function postLoginRedirector({
  token,
  route = '/home',
}: {
  token: string;
  route: string;
}) {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Authorization', `Token ${token}`);

  const fetchAssociatedCompanies = await fetch('/api/v1/company', {
    headers,
  });

  if (fetchAssociatedCompanies.ok) {
    const associatedCompanies = await fetchAssociatedCompanies.json();
    if (associatedCompanies.length > 1) {
      return window.location.replace('/home/#/select-company');
    }
  }

  const [path, queryParams] = route.split('?');

  if (queryParams) {
    const encodedQueryParams = queryParams
      .split('&')
      .map(param => {
        const [key, value] = param.split('=');
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join('&');
    const nextRoute = `${path}?${encodedQueryParams}`;
    return window.location.replace(nextRoute);
  }

  return window.location.replace(route);
}
