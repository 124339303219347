import { createContext, useContext } from 'react';
import { UseFormReturnType, useForm } from '@mantine/form';

import { ForgotPasswordRequest } from '../../@types/auth';

type IForgotPasswordFormContext = UseFormReturnType<ForgotPasswordRequest>;

const ForgotPasswordFormContext = createContext<
  IForgotPasswordFormContext | undefined
>(undefined);

export function useForgotPasswordFormContext() {
  const forgotPasswordForm = useContext(ForgotPasswordFormContext);

  if (!forgotPasswordForm) {
    throw new Error(
      'useForgotPasswordFormContext must be used within a ForgotPasswordFormProvider',
    );
  }

  return forgotPasswordForm;
}

export const ForgotPasswordFormProvider = ({
  children,
}: React.ComponentPropsWithoutRef<'div'>) => {
  const form = useForm<ForgotPasswordRequest>({
    initialValues: {
      email: '',
    },
    validate: values => ({
      email: !values.email ? 'Email is required' : null,
    }),
  });

  return (
    <ForgotPasswordFormContext.Provider value={form}>
      {children}
    </ForgotPasswordFormContext.Provider>
  );
};
