import { createContext, useContext } from 'react';
import { UseFormReturnType, useForm } from '@mantine/form';

import { passwordValidator } from '../../utils';

interface ResetPasswordFormValues {
  password: string;
  password_confirmation: string;
}

type IResetPasswordFormContext = UseFormReturnType<ResetPasswordFormValues>;

const ResetPasswordFormContext = createContext<
  IResetPasswordFormContext | undefined
>(undefined);

export function useResetPasswordFormContext() {
  const resetPasswordForm = useContext(ResetPasswordFormContext);

  if (!resetPasswordForm) {
    throw new Error(
      'useResetPasswordFormContext must be used within a ResetPasswordFormProvider',
    );
  }

  return resetPasswordForm;
}

export const ResetPasswordFormProvider = ({
  children,
}: React.ComponentPropsWithoutRef<'div'>) => {
  const form = useForm<ResetPasswordFormValues>({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validate: values => ({
      password: passwordValidator(values.password),
      password_confirmation:
        values.password !== values.password_confirmation
          ? 'Passwords do not match'
          : null,
    }),
  });

  return (
    <ResetPasswordFormContext.Provider value={form}>
      {children}
    </ResetPasswordFormContext.Provider>
  );
};
