import { Suspense, lazy, useEffect } from 'react';
import {
  createHashRouter,
  RouterProvider,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';

import {
  LogInFormProvider,
  TwoFactorFormProvider,
  SignUpFormProvider,
  ForgotPasswordFormProvider,
  ResetPasswordFormProvider,
} from './contexts/forms';

import Layout from './components/Layout';

import { GOOGLE_RECAPTCHA_SITE_KEY } from './constants/keys';

const LogIn = lazy(() => import('./pages/LogIn'));
const TwoFactor = lazy(() => import('./pages/TwoFactor'));
const SignUp = lazy(() => import('./pages/SignUp'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

const IndexRedirect = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/login?${searchParams.toString()}`);
  }, [navigate, searchParams]);

  return null;
};

const router = createHashRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <IndexRedirect />,
      },
      {
        path: 'login',
        element: (
          <Suspense>
            <LogInFormProvider>
              <LogIn />
            </LogInFormProvider>
          </Suspense>
        ),
      },
      {
        path: 'login/two-factor',
        element: (
          <Suspense>
            <TwoFactorFormProvider>
              <TwoFactor />
            </TwoFactorFormProvider>
          </Suspense>
        ),
      },
      {
        path: 'signup',
        element: (
          <Suspense>
            <SignUpFormProvider>
              <SignUp />
            </SignUpFormProvider>
          </Suspense>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <Suspense>
            <ForgotPasswordFormProvider>
              <ForgotPassword />
            </ForgotPasswordFormProvider>
          </Suspense>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <Suspense>
            <ResetPasswordFormProvider>
              <ResetPassword />
            </ResetPasswordFormProvider>
          </Suspense>
        ),
      },
    ],
  },
]);

export default function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  }, []);

  return <RouterProvider router={router} />;
}
