import { Flex, Anchor, Image } from '@mantine/core';
import { useOs } from '@mantine/hooks';

import PlayStore from '../../assets/images/google_play_store.png';
import AppStore from '../../assets/images/apple_app_store.png';
import AppGallery from '../../assets/images/huawei_appgallery.png';

export default function MobileAppLinks() {
  const os = useOs();

  return (
    <Flex my="lg" gap="lg" justify="center" wrap="wrap">
      {os !== 'android' ? (
        <Anchor
          href="https://itunes.apple.com/app/kakitangan/id1358869881"
          target="_blank"
          rel="noreferrer noopener"
          aria-label="Download iOS App"
        >
          <Image src={AppStore} alt="Apple App Store Logo" w={153} h={45} />
        </Anchor>
      ) : null}
      {os !== 'ios' ? (
        <>
          <Anchor
            href="https://play.google.com/store/apps/details?id=com.kakitangan"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Download Android App"
          >
            <Image src={PlayStore} alt="Play Store Logo" w={153} h={45} />
          </Anchor>
          <Anchor
            href="https://appgallery.huawei.com/app/C104680079"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Download Huawei App"
          >
            <Image
              src={AppGallery}
              alt="Huawei App Gallery Logo"
              w={153}
              h={45}
            />
          </Anchor>
        </>
      ) : null}
    </Flex>
  );
}
