import { Flex, Text, Anchor, Stack } from '@mantine/core';
import { Outlet } from 'react-router-dom';

import MobileAppLinks from '../MobileAppLinks';

import classes from './Layout.module.scss';

export default function Layout() {
  return (
    <Flex className={classes.root} justify="center">
      <Flex
        className={classes.container}
        direction="column"
        justify="center"
        align="center"
      >
        <Outlet />
        <br />
        <Stack className={classes.footer}>
          <div className={classes.text}>
            <Text size="xs">This page is protected by reCAPTCHA.</Text>
            <Text size="xs">
              Google&apos;s{' '}
              <Anchor href="https://policies.google.com/privacy">
                Privacy Policy
              </Anchor>{' '}
              and{' '}
              <Anchor href="https://policies.google.com/terms">
                Terms of Service
              </Anchor>{' '}
              apply.
            </Text>
          </div>
          <MobileAppLinks />
        </Stack>
      </Flex>
    </Flex>
  );
}
