import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MantineProvider, Badge, createTheme } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import App from './App';

import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import './assets/styles/index.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60000,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const theme = createTheme({
  primaryColor: 'kt-blue',
  colors: {
    'kt-blue': [
      '#B0F2FB',
      '#9DEFFA',
      '#76E8F9',
      '#4FE2F7',
      '#28DBF5',
      '#0BCDE9',
      '#09ABC2',
      '#077C8C',
      '#044C57',
      '#021D21',
    ],
  },
  components: {
    Badge: Badge.extend({
      styles: {
        label: {
          textTransform: 'none',
          fontWeight: 'normal',
        },
      },
    }),
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <Notifications position="bottom-center" zIndex={1000} />
        <App />
      </MantineProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);
